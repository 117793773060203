@use "src/assets/styles/colors";

.profile-view {
  margin-top: 32px;

  .menu-back-row {
    display: inline-flex;
    align-items: center;

    .menu-back-btn {
      margin-right: 8px;
    }

    .menu-back-text {
      padding-bottom: 2px;
    }
  }

  .menu {
    margin-top: 26px;
    flex-direction: column;
    display: flex;

    .profile-subtitle {
      padding-left: 20px;
      padding-right: 20px;
    }

    .profile-content-container {
      padding-left: 20px;
      padding-right: 20px;

      .profile-label {
        color: colors.$dark-green;
        margin-bottom: 12px;
      }

      .add-bank-account-btn {
        color: colors.$dark-green;
        text-decoration-color: rgba($color: colors.$dark-green, $alpha: 0.4);
      }

      .to-bank-details-btn {
        color: rgba($color: colors.$dark-green, $alpha: 0.56);
      }
    }

    .menu-divider {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
}
