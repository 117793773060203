@use "src/assets/styles/colors";

nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div.needHelp {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
