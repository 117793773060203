@use "src/assets/styles/colors";

.menu-fullscreen {
  margin-top: 32px;

  .menu-back-row {
    display: inline-flex;
    align-items: center;

    .menu-back-btn {
      margin-right: 8px;
    }

    .menu-back-text {
      padding-bottom: 2px;
    }
  }

  .menu {
    margin-top: 26px;
    flex-direction: column;
    display: flex;

    .menu-user-info {
      padding-left: 20px;
      padding-right: 20px;

      .menu-email-address {
        color: rgba(colors.$dark-green, 0.6);
      }
    }

    .menu-divider {
      margin-top: 12px;
    }

    .menu-icon {
      margin-right: 16px;
    }

    .menu-row {
      display: inline-flex;
      align-items: center;
      margin-top: 12px;
      padding-left: 12px;
      padding-right: 12px;
      // &:hover {
      //   background-color: red;
      // }
    }
  }
}
