@use "src/assets/styles/colors";

.login-email-label {
  opacity: 60%;
}

.login-email-label-focused {
  opacity: 100%;
}

.login-email-input {
  padding-top: 1.125px;
  padding-bottom: 1.125px;
}
