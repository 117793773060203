$primary-50: #faf8ff;
$primary-100: #f5f1ff;
$primary-200: #ece4ff;
$primary-300: #e2d6ff;
$primary-400: #d9c9ff;
$primary-500: #cfbbff;
$primary-600: #ae96e9;
$primary-700: #8d70d3;
$primary-800: #6d4bbe;
$primary-900: #4c25a8;

$neutral-50: #eaeaea;
$neutral-100: #d2d4da;
$neutral-200: #d2d4da;
$neutral-300: #b3b5bd;
$neutral-400: #9496a1;
$neutral-500: #777986;
$neutral-600: #5b5d6b;
$neutral-700: #404252;
$neutral-800: #282a3a;
$neutral-900: #101223;

$success-50: #edf8f4;
$success-100: #dbf0e8;
$success-200: #b7e1d1;
$success-300: #94d3ba;
$success-400: #70c4a3;
$success-500: #4cb58c;
$success-600: #3d9170;
$success-700: #2e6d54;
$success-800: #1e4838;
$success-900: #0f241c;

$warning-50: #fff3e6;
$warning-200: #fee7cc;
$warning-200: #fdce99;
$warning-300: #fdb666;
$warning-400: #fc9d33;
$warning-500: #fb8500;
$warning-600: #c96a00;
$warning-700: #975000;
$warning-800: #643500;
$warning-900: #321b00;

$error-50: #fdebed;
$error-100: #fad7da;
$error-200: #f5b0b5;
$error-300: #f08890;
$error-400: #eb616b;
$error-500: #e63946;
$error-600: #b82e38;
$error-700: #8a222a;
$error-800: #5c171c;
$error-900: #2e0b0e;

$purple: #842eff;
$light-purple: #cfbbff;
$red: #ef466f;
$gray: #cccccc;
$green: #45b26b;
$light-black: #333844;
$primary-icon: #842eff;
$primary-button: #c9b3ff;
$disabled-button: #d2d4da;
$disbaled-button-text: #9496a1;
$primary-button-hover: #a4c900;
$button-text: #000000;
$input-text-black: #000000;
$input-placeholder-text: #a4a4a4;
$input-background: rgba(236, 236, 236, 0.25);
$primary: #af8eff;
$neutral-black: #09101d;

$beam-light-purple: #cbbbfa;
$beam-slime-green: #d6fb50;
$beam-neutral-black: #101122;

// Core colors
$payday-green: #84fab3;
$esmerelda: #20c563;
$golden-sunset: #ffcb77;
$racing-green: #20452f;
$olive: #545c52;

// Secondary colors
$atomic-orange: #ff9b71;
$flax-gold: #f9e784;
$robin-blue: #17d1cb;
$soft-pink: #fdcbd6;
$dark-purple: #242038;
$umber: #594236;
$outer-space: #46494c;
$chocolate-cosmos: #481d24;
$text-secondary: rgba(1, 25, 15, 0.6);

// Neutral colors
$white: #ffffff;
$black: #000000;
$seasalt: #f6f6f7;
$platinum: #e4e5e6;
$silver: #c1c2c4;
$dim-gray: #696c6e;
$timberwolf: #2a2c2e;
$dark-green: #01190f;

// Sentiment colors
$negative: #e63946;
$positive: #20c563;
$warning-main: #ed6c02;

// NOT SURE IF THESE ARE STILL NEEDED
$primary10: #310be810;
$neutral-black90: #09101d90;
$neutral-white: #ffffff;
$neutral-8: #ebeef2;

$states-success: #23a757;
$states-warning: #b95000;
$states-error: #da1414;
$states-info: #2e5aac;

$states-success-background: #edf9f0;
$states-warning-background: #fff4ec;
$states-error-background: #feefef;
$states-info-background: #eef2fa;

$action-primary-default: $primary;
$action-primary-hover: #5a3ced;
$action-primary-disabled: #9784f3;
$action-neutral-default: #9098a1;
$action-neutral-hover: #858c94;
$action-neutral-disabled: #9098a170;
