@use "src/assets/styles/colors";

.menu-back-row {
  display: inline-flex;
  align-items: center;

  .menu-back-btn {
    margin-right: 8px;
  }
}
