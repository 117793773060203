//noinspection CssInvalidAtRule
@import-normalize;

main {
  height: 100vh;
}

$eightPointSizes: 4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96;

@each $size in $eightPointSizes {
  .mb-#{$size} {
    margin-bottom: #{$size / 10}rem;
  }

  .mt-#{$size} {
    margin-top: #{$size / 10}rem;
  }

  .ml-#{$size} {
    margin-left: #{$size / 10}rem;
  }

  .mr-#{$size} {
    margin-right: #{$size / 10}rem;
  }
}

.persona-widget__iframe {
  height: 100vh;
  width: 100vw;
}
