@use "src/assets/styles/colors";

.no-transactions-preview-container {
  width: 100%;
  display: flex;
}

.transactions-preview-container {
  border-color: rgba($color: #000000, $alpha: 0.12);
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  align-items: center;
  padding-bottom: 16px;
  padding-top: 16px;

  .transactions-preview-subtitle-row {
    margin-bottom: 16px;
  }

  .transactions-preview-row {
    width: 100%;
    display: inline-flex;
    padding-left: 16px;
    padding-right: 16px;
  }

  .transactions-preview-see-all-icon-btn {
    color: colors.$dark-green;
    opacity: 56%;
    padding: 0px;
    margin-right: 8px;
  }
}
