@use "src/assets/styles/colors";

.transactions-container {
  margin-top: 32px;

  .transactions-back-row {
    display: inline-flex;
    align-items: center;

    .transactions-back-btn {
      margin-right: 8px;
    }

    .transactions-back-text {
      padding-bottom: 2px;
    }
  }

  .transactions-rows-container {
    margin-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    max-height: 80vh;
    overflow-y: scroll;
  }
}
