@use "src/assets/styles/colors";

.payout-estimator-container {
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 8px;
  font-family: Manrope;

  .estimator-see-terms-text {
    opacity: 0.6;
    margin-top: 8px;
    font-size: 12px;
    text-align: start;
  }

  .payout-estimator-currency-amount-container {
    margin-bottom: 8px;

    .payout-estimator-currency-amount {
      .MuiInputBase-input {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 14px;
        padding-right: 14px;
        color: colors.$text-secondary;
      }
    }

    .payout-estimator-currency-amount-focused {
      border-color: colors.$text-secondary;
      outline-color: colors.$text-secondary;
      color: colors.$text-secondary;
    }
  }

  .payout-estimator-currency-select-container {
    margin-bottom: 8px;
    padding-left: 0px;

    .payout-estimator-currency-select {
      margin-right: 8px;
    }

    .payout-estimator-currency-select {
      .MuiSelect-select {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 14px;
        padding-right: 38px;
        color: colors.$text-secondary;
      }
    }
  }

  .payout-estimator-exchange-rate-label {
    color: colors.$text-secondary;
  }

  .payout-estimator-exchange-rate-value {
    color: colors.$text-secondary;
  }

  .MuiTypography-body2 {
    color: colors.$dark-green;
    margin-top: 8px;
  }
}

.payout-estimator-currency-select-item {
  color: colors.$text-secondary !important;
}
