@use "src/assets/styles/colors";

.bank-details-container {
  margin-top: 32px;

  .menu-back-row {
    display: inline-flex;
    align-items: center;

    .menu-back-btn {
      margin-right: 8px;
    }

    .menu-back-text {
      padding-bottom: 2px;
    }
  }

  .profile-subtitle {
    padding-left: 20px;
    padding-right: 20px;
  }

  .menu {
    margin-top: 26px;

    .profile-content-container {
      padding-left: 20px;
      padding-right: 20px;

      .primary-switch-label {
        font-weight: 700;
      }

      .remove-warning-text {
        font-size: 10px;
        color: rgba($color: colors.$dark-green, $alpha: 0.6);
        max-width: 50%;

        // desktop
        @media screen and (min-width: 979px) {
          max-width: 55%;
        }
      }

      .add-backup-btn {
        width: 100%;
        color: rgba($color: #000000, $alpha: 0.87);
        border-color: rgba($color: #000000, $alpha: 0.87);
      }

      .primary-switch-container {
        margin-top: 20px;
      }

      .remove-payout-container {
        margin-top: 20px;
      }

      .add-backup-container {
        margin-top: 40px;
      }
    }
  }

  .menu-divider {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
