@use "src/assets/styles/colors";

.menu-popover {
  .menu-email-address {
    color: rgba(colors.$dark-green, 0.6);
  }

  .menu-list-item-text {
    margin-right: 32px;
  }
}
