@use "src/assets/styles/colors";

.feedback-widget-btn {
  background-color: #ffffff;
}

.feedback-widget-label {
  text-transform: none;
  color: colors.$dark-green;
}
