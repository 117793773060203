@use "src/assets/styles/colors";

.transaction-info-row-chain-icon {
  display: flex;
  align-items: center;
  padding-left: 0px !important;
  // height: 32px;
  // width: 32px;
}

.transaction-info-row {
  width: 100%;

  .transaction-info-inner-row {
    justify-content: space-between;
    display: flex;
  }

  .transaction-info-bottom-inner-row {
    margin-top: 8px;
  }

  .transaction-info-row-text {
    display: inline-flex;
  }

  .transaction-info-timestamp {
    color: rgba($color: colors.$dark-green, $alpha: 0.6);
  }

  .transaction-info-timestamp-dot {
    margin-bottom: 2px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .transaction-info-status {
    display: inline-flex;
    align-items: center;
  }

  .transaction-info-status-icon {
    margin-left: 4px;
    font-size: 16px;
  }

  .transaction-info-status-processing {
    color: rgba($color: colors.$dark-green, $alpha: 0.6);
  }

  .transaction-info-status-completed {
    color: colors.$positive;
  }

  .transaction-info-status-inreview {
    color: colors.$warning-main;
  }
}
